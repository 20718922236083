
import { Options, Vue, Watch } from "vue-property-decorator";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { IonContent, IonButton } from "@ionic/vue";
import DefaultHeader from "@/components/Headers/DefaultHeader.vue";

@Options({
  components: { DefaultHeader, IonContent, IonButton },
})
export default class QrCodeScanner extends Vue {
  public name = "QrCodeScanner";

  public scanActive = false;
  public result: string | undefined = "";

  public async startScan() {
    await BarcodeScanner.hideBackground(); // make background of WebView transparent

    const allowed = await this.checkPermission();
    if (allowed) {
      this.scanActive = true;

      const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        this.result = result.content;
        this.scanActive = false;
      }
    }
  }

  public stopScan() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    this.scanActive = false;
  }

  public async checkPermission() {
    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.denied) {
      // the user denied permission for good
      // redirect user to app settings if they want to grant it anyway
      const c = confirm("If you want to grant permission for using your camera, enable it in the app settings.");

      if (c) {
        await BarcodeScanner.openAppSettings();
        return false;
      }
    } else if (status.granted) {
      return true;
    }
    return false;
  }

  public beforeUnmount() {
    const huraEl = document.getElementById("guest");
    huraEl?.classList.add("app-bg");
    this.stopScan();
  }

  public mounted(): void {
    this.$nextTick(() => {
      const huraEl = document.getElementById("guest");
      huraEl?.classList.remove("app-bg");
    });
    this.startScan();
  }

  @Watch("result")
  public onChangeResult(result: string) {
    this.$emit("update:modelValue", result);
  }

  public onBack(): void {
    this.$router.go(-1);
  }
}
